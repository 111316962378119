import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	Heading,
	Text,
	Box,
	Button,
} from '@tempo/core';
import {useTranslation} from 'next-i18next';

interface UnlinkModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

const UnlinkModal: React.FC<UnlinkModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
}) => {
	const {t} = useTranslation();

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent padding="spacing.3xl" data-testid="unlink-modal">
				<Heading
					overflow="hidden"
					color="text.neutral.primary.default"
					textOverflow="ellipsis"
					variant="heading.l"
					fontStyle="normal"
					fontWeight={700}
					marginBottom="spacing.l"
				>
					{t('confirmationmodal_title_unlinkaccount_d4p-web')}
				</Heading>
				<Text
					marginBottom="spacing.xl"
					overflow="hidden"
					color="text.neutral.secondary.default"
					textOverflow="ellipsis"
					fontStyle="normal"
					fontWeight={400}
				>
					{t('confirmationmodal_text_wanttounlinkaccount_d4p-web')}
				</Text>
				<Box display="flex" justifyContent="end">
					<Button
						variant="ghost"
						mr="spacing.s"
						onClick={onClose}
						data-testid="unlink-cancel"
					>
						{t('generic_action_cancel_d4p-web')}
					</Button>
					<Button onClick={onConfirm} data-testid="unlink-confirm">
						{t('generic_action_confirm_d4p-web')}
					</Button>
				</Box>
			</ModalContent>
		</Modal>
	);
};

export default UnlinkModal;
