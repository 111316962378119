import React, {useState} from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	Heading,
	Text,
	Box,
	Button,
	Input,
	FormControl,
	FormLabel,
	FormHelperText,
	FormErrorMessage,
	VStack,
} from '@tempo/core';
import type {LinkFormData} from '@components/CustomerDetails';
import {validateEmail} from '@utils/validators';
import {useTranslation} from 'next-i18next';

interface ForceActivationModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	linkFormData: LinkFormData | null;
	setLinkFormData: (linkData: LinkFormData) => void;
}

const ForceActivationModal: React.FC<ForceActivationModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
	linkFormData,
	setLinkFormData,
}) => {
	const [emailError, setEmailError] = useState<string | null>(null);
	const isEmailEmpty = !linkFormData?.email?.trim();
	const {t} = useTranslation();

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const email = e.target.value;
		setLinkFormData({...linkFormData, email});

		if (emailError) {
			setEmailError(null);
		}
	};

	const handleActivate = () => {
		const email = linkFormData?.email || '';
		const error = validateEmail(
			email.trim(),
			t('errormessage_text_invalidemail_d4p-web'),
		);

		if (error) {
			setEmailError(error);
			return;
		}

		setEmailError(null);
		onConfirm();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent padding="spacing.3xl" data-testid="force-activation-modal">
				<Heading
					overflow="hidden"
					color="text.neutral.primary.default"
					textOverflow="ellipsis"
					variant="heading.l"
					fontStyle="normal"
					fontWeight={700}
					marginBottom="spacing.l"
				>
					{t('confirmationmodal_title_forcelink_d4p-web')}
				</Heading>
				<Text
					marginBottom="spacing.xl"
					overflow="hidden"
					textOverflow="ellipsis"
					fontStyle="normal"
					fontWeight={400}
				>
					{t('confirmationmodal_text_forcelinkdescription_d4p-web')}
				</Text>
				<VStack spacing="spacing.xl" mb="spacing.xl">
					<FormControl isRequired isInvalid={!!emailError}>
						<FormLabel htmlFor="email" color="text.neutral.primary.default">
							{t('generic_title_emailaddress_d4p-web')}
						</FormLabel>
						<Input
							id="email"
							type="email"
							value={linkFormData?.email || ''}
							onChange={handleEmailChange}
						/>
						{emailError && (
							<FormErrorMessage data-testid="email-error">
								{emailError}
							</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isRequired>
						<FormLabel
							htmlFor="subscriptionId"
							color="text.neutral.primary.default"
						>
							{t('subinfo_title_subscriberid_d4p-web')}
						</FormLabel>
						<Input
							id="subscriptionId"
							value={linkFormData?.subscriptionId}
							disabled
						/>
						<FormHelperText
							color="text.neutral.secondary.default"
							fontSize="var(--Accessibility-12, 12px)"
						>
							{t('subinfo_title_subscriberid_d4p-web')}
						</FormHelperText>
					</FormControl>
					<Text
						alignSelf="flex-end"
						color="text.neutral.secondary.default"
						fontSize="var(--Accessibility-14, 14px)"
					>
						<span style={{color: 'var(--tempo-colors-red-500)'}}>* </span>
						<span>{t('formerror_title_mandatory_d4p-web')}</span>
					</Text>
				</VStack>
				<Box display="flex" justifyContent="end">
					<Button
						variant="ghost"
						mr="spacing.s"
						onClick={onClose}
						data-testid="activate-cancel-button"
					>
						{t('generic_action_cancel_d4p-web')}
					</Button>
					<Button
						onClick={handleActivate}
						isDisabled={isEmailEmpty}
						data-testid="activate-confirm-button"
					>
						{t('generic_action_activate_d4p-web')}
					</Button>
				</Box>
			</ModalContent>
		</Modal>
	);
};

export default ForceActivationModal;
