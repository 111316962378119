import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	Heading,
	Text,
	Box,
	Button,
	Input,
	FormControl,
	FormLabel,
	VStack,
} from '@tempo/core';
import {useTranslation} from 'next-i18next';

interface SendPasswordLinkModalProps {
	email?: string;
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

const SendPasswordLinkModal: React.FC<SendPasswordLinkModalProps> = ({
	email,
	isOpen,
	onClose,
	onConfirm,
}) => {
	const {t} = useTranslation();

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				padding="spacing.3xl"
				data-testid="send-password-link-modal"
			>
				<Heading
					overflow="hidden"
					color="text.neutral.primary.default"
					textOverflow="ellipsis"
					variant="heading.l"
					fontStyle="normal"
					fontWeight={700}
					marginBottom="spacing.l"
				>
					{t('passwordrecoverymodal_title_sendlink_d4p-web')}
				</Heading>
				<Text
					marginBottom="spacing.xl"
					overflow="hidden"
					color="text.neutral.secondary.default"
					textOverflow="ellipsis"
					fontStyle="normal"
					fontWeight={400}
				>
					{t('passwordrecoverymodal_text_sendlinkdescription_d4p-web')}
				</Text>
				<VStack spacing="spacing.xl" mb="spacing.xl">
					<FormControl>
						<FormLabel htmlFor="email" color="text.neutral.primary.default">
							{t('generic_title_emailaddress_d4p-web')}
						</FormLabel>
						<Input
							id="email"
							type="email"
							value={email || ''}
							disabled
							data-testid="send-password-email"
						/>
					</FormControl>
				</VStack>
				<Box display="flex" justifyContent="end">
					<Button
						variant="ghost"
						mr="spacing.s"
						onClick={onClose}
						data-testid="send-password-cancel"
					>
						{t('generic_action_cancel_d4p-web')}
					</Button>
					<Button onClick={onConfirm} data-testid="send-password-confirm">
						{t('generic_action_sendemail_d4p-web')}
					</Button>
				</Box>
			</ModalContent>
		</Modal>
	);
};

export default SendPasswordLinkModal;
