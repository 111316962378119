import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	Heading,
	Text,
	Box,
	Button,
	VStack,
	Input,
} from '@tempo/core';
import {useTranslation} from 'next-i18next';

interface CopyPasswordLinkModalProps {
	link: string;
	isOpen: boolean;
	onClose: () => void;
}

const CopyPasswordLinkModal: React.FC<CopyPasswordLinkModalProps> = ({
	link,
	isOpen,
	onClose,
}) => {
	const addLinkToClipboard = () => {
		navigator.clipboard.writeText(link);
	};
	const {t} = useTranslation();

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				padding="spacing.3xl"
				data-testid="copy-password-link-modal"
			>
				<Heading
					overflow="hidden"
					color="text.neutral.primary.default"
					textOverflow="ellipsis"
					variant="heading.l"
					fontStyle="normal"
					fontWeight={700}
					marginBottom="spacing.l"
				>
					{t('passwordrecoverymodal_title_copylink_d4p-web')}
				</Heading>
				<Text
					marginBottom="spacing.xl"
					overflow="hidden"
					color="text.neutral.secondary.default"
					textOverflow="ellipsis"
					fontStyle="normal"
					fontWeight={400}
				>
					{t('passwordrecoverymodal_text_copylinkdescription_d4p-web')}
				</Text>
				<VStack spacing="spacing.xl" mb="spacing.xl">
					<Input
						type="text"
						value={link}
						readOnly
						data-testid="password-renewal-link"
					/>
				</VStack>
				<Box display="flex" justifyContent="end">
					<Button
						variant="ghost"
						mr="spacing.s"
						onClick={onClose}
						data-testid="copy-link-cancel"
					>
						{t('generic_action_cancel_d4p-web')}
					</Button>
					<Button onClick={addLinkToClipboard} data-testid="copy-link-confirm">
						{t('generic_action_copylink_d4p-web')}
					</Button>
				</Box>
			</ModalContent>
		</Modal>
	);
};

export default CopyPasswordLinkModal;
