import type {Button} from '@tempo/core';
import {Box, Text, Tr, Td, Label} from '@tempo/core';
import type {DeezerLogoIcon} from '@tempo/icons';
import {useTranslation} from 'next-i18next';
import React from 'react';

export const TableRow: React.FC<{
	icon: typeof DeezerLogoIcon;
	label?: string;
	value?: string | number;
	buttons?: React.ReactElement<typeof Button>[];
	isLastRow?: boolean;
	isStatus?: boolean;
	isActivated?: boolean;
	dataTestId?: string;
}> = ({
	icon: Icon,
	label,
	value,
	buttons,
	isLastRow = false,
	isStatus = false,
	isActivated = false,
	dataTestId,
}) => {
	const {t} = useTranslation();

	return (
		<Tr>
			<Td
				display="flex"
				p="spacing.l"
				alignItems="center"
				gap="spacing.l"
				alignSelf="stretch"
				borderBottom={isLastRow ? 'none' : '1px solid'}
				borderBottomColor={
					isLastRow ? 'transparent' : 'divider.neutral.primary.default'
				}
			>
				<Icon size="medium" />
				<Box>
					<Text
						alignSelf="stretch"
						color="text.neutral.secondary.default"
						fontSize="var(--Accessibility-12, 12px)"
						fontStyle="normal"
						fontWeight={400}
						lineHeight="var(--Accessibility-16, 16px)"
					>
						{label}
					</Text>
					{isStatus ? (
						<Label
							bg={
								isActivated
									? 'background.feedback.success.default'
									: 'background.feedback.success.disabled'
							}
							color={
								isActivated
									? 'text.neutral.inverse.default'
									: 'text.neutral.primary.default'
							}
							data-testid={dataTestId}
						>
							{isActivated
								? t('status_title_active_d4p-web')
								: t('status_title_notactivated_d4p-web')}
						</Label>
					) : (
						<Text
							alignSelf="stretch"
							color="text.neutral.primary.default"
							fontSize="var(--Accessibility-16, 16px)"
							fontStyle="normal"
							fontWeight={400}
							lineHeight="var(--Accessibility-24, 24px)"
							data-testid={dataTestId}
						>
							{value}
						</Text>
					)}
					<Box
						display="flex"
						gap="8px"
						mt="4px"
						flexDirection={{base: 'column', sm: 'row'}}
					>
						{buttons &&
							buttons.map((button, index) => (
								<React.Fragment key={index}>{button}</React.Fragment>
							))}
					</Box>
				</Box>
			</Td>
		</Tr>
	);
};
