import {z} from 'zod';

const emailSchema = (message: string) => z.string().email({message});

const msisdnRegex = /^\+?\d{6,15}$/;

const phoneSchema = (message: string) =>
	z.string().refine((value) => msisdnRegex.test(value), {message});

const validateInputWithSchema = (input: string, schema: z.ZodSchema) => {
	if (input === '') {
		return '';
	}
	const result = schema.safeParse(input.trim());
	return result.success ? '' : result.error.issues[0].message;
};

export const validateSearchInput = (
	input: string,
	type: 'email' | 'msisdn' | 'code',
	message: string,
) => {
	switch (type) {
		case 'email':
			return validateInputWithSchema(input, emailSchema(message));
		case 'msisdn':
			return validateInputWithSchema(input, phoneSchema(message));
		case 'code':
		default:
			return '';
	}
};

export const validateEmail = (input: string, message: string) => {
	return validateInputWithSchema(input, emailSchema(message));
};
