const isDateValid = (date: string) => {
	return !isNaN(new Date(date).getTime());
};

export const formatDate = (date: string) => {
	if (!isDateValid(date)) {
		return '-';
	} else {
		return new Date(date).toLocaleDateString();
	}
};
