import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	Heading,
	Text,
	Button,
	Box,
} from '@tempo/core';
import {CrossCircleFilledIcon} from '@tempo/icons';
import {useTranslation} from 'next-i18next';

interface ForceActivationErrorModalProps {
	isOpen: boolean;
	onClose: () => void;
	onRetry?: () => void;
	errorCode?: number;
}

const ForceActivationErrorModal: React.FC<ForceActivationErrorModalProps> = ({
	isOpen,
	onClose,
	onRetry,
	errorCode,
}) => {
	const {t} = useTranslation();

	const getErrorMessage = () => {
		switch (errorCode) {
			case 4040:
			case 4045:
				return t('errormessage_title_forcedactivationfreemiumonly_d4p-web');
			case 4020:
				return t('errormessage_text_subscriptionlinked_d4p-web');
			case 4035:
				return t('errormessage_text_subscribernotfound_d4p-web');
			default:
				return t('errormessage_text_technicalornetworkissue_d4p-web');
		}
	};

	const getErrorTitle = () => {
		switch (errorCode) {
			case 4040:
			case 4045:
			case 4020:
			case 4035:
				return t('errormessage_title_failedactivation_d4p-web');
			default:
				return t('errormessage_title_unsuccessful_d4p-web');
		}
	};

	const showRetry = ![4040, 4045, 4020, 4035].includes(errorCode || 0);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				padding="spacing.3xl"
				display="flex"
				flexDirection="column"
				alignItems="center"
				data-testid="force-activation-error-modal"
			>
				<CrossCircleFilledIcon
					color="icon.feedback.error.default"
					boxSize="64px"
					marginBottom="spacing.l"
				/>
				<Heading
					overflow="hidden"
					color="text.neutral.primary.default"
					textOverflow="ellipsis"
					variant="heading.l"
					fontStyle="normal"
					fontWeight={700}
					marginBottom="spacing.l"
					data-testid="error-modal-header"
				>
					{getErrorTitle()}
				</Heading>
				<Text
					marginBottom="spacing.xl"
					overflow="hidden"
					color="text.neutral.secondary.default"
					textOverflow="ellipsis"
					fontStyle="normal"
					fontWeight={400}
				>
					{getErrorMessage()}
				</Text>
				<Box display="flex" justifyContent="center">
					{showRetry && onRetry && (
						<Button
							variant="outline"
							mr="spacing.s"
							onClick={onRetry}
							data-testid="error-modal-retry-button"
						>
							{t('generic_action_tryagain_d4p-web')}
						</Button>
					)}
					<Button
						onClick={onClose}
						data-testid="error-modal-close-button"
						variant={showRetry ? 'ghost' : 'outline'}
					>
						{t('generic_action_close_d4p-web')}
					</Button>
				</Box>
			</ModalContent>
		</Modal>
	);
};

export default ForceActivationErrorModal;
