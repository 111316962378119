import React from 'react';

import type {
	GetStaticProps,
	GetStaticPropsContext,
	GetStaticPropsResult,
} from 'next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';

import {Box} from '@tempo/core';
import Search from '@components/Search';
import {withAuth} from '@hocs/withAuth';

function Index() {
	return (
		<>
			<Box display="flex" height="100vh">
				<Box
					flex={1}
					px="spacing.l"
					py="spacing.m"
					display="flex"
					justifyContent="center"
					overflow="auto"
				>
					<Search />
				</Box>
			</Box>
		</>
	);
}

export const getStaticProps: GetStaticProps = async ({
	locale,
	locales,
}): Promise<GetStaticPropsResult<GetStaticPropsContext>> => {
	return {
		props: {
			locale,
			locales,
			...(await serverSideTranslations(locale || '', ['common', 'd4p'])),
		},
	};
};

export default withAuth(Index);
