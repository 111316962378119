import {
	Box,
	Heading,
	Button,
	InputGroup,
	InputLeftElement,
	Input,
	FormControl,
	FormErrorMessage,
} from '@tempo/core';
import React, {useState, useCallback} from 'react';
import type {FormikHelpers} from 'formik';
import {Formik} from 'formik';
import type {CustomerDetailsProps} from '@components/CustomerDetailsTable';
import CustomerDetails from '@components/CustomerDetails';
import {ChevronLeftIcon, SearchOutlinedIcon} from '@tempo/icons';
import useWindowSize from '@hooks/useWindowSize';
import {validateSearchInput} from '@utils/validators';
import type {IncludedItem, PartnerAttributes} from '@pages/api/home';
import {useHomeData} from '@hooks/useHomeData';
import {useTranslation} from 'next-i18next';

const SearchComponent: React.FC = () => {
	const [searchTerm, setSearchTerm] = useState('');
	const [showResults, setShowResults] = useState(false);
	const [searchData, setSearchData] = useState<CustomerDetailsProps | null>(
		null,
	);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const windowSize = useWindowSize();
	const isMobile = windowSize.width !== undefined && windowSize.width <= 768;
	const {data} = useHomeData();
	const partnerAttributes = data?.included?.find(
		(i): i is IncludedItem & {attributes: PartnerAttributes} =>
			i.type === 'Partner',
	)?.attributes;
	const {activationType} = partnerAttributes || {};
	const {t} = useTranslation();
	const {t: tLegacy} = useTranslation('common');

	const getInputPlaceholder = () => {
		switch (activationType) {
			case 'email':
				return t('inputfield_action_searchbyemail_d4p-web');
			case 'msisdn':
				return t('inputfield_action_searchbyphone_d4p-web');
			case 'code':
			default:
				return t('inputfield_action_searchbyID_d4p-web');
		}
	};

	const getValidationErrorMessage = () => {
		switch (activationType) {
			case 'email':
				return t('errormessage_text_invalidemail_d4p-web');
			case 'msisdn':
				return t('errormessage_text_invalidphone_d4p-web');
			case 'code':
			default:
				return '';
		}
	};

	const fetchData = useCallback(async (term: string) => {
		setIsLoading(true);
		setError(null);

		try {
			const response = await fetch(
				`/api/search?searchTerm=${encodeURIComponent(term)}&include=user,offer`,
			);
			if (!response.ok) {
				throw new Error('Search failed');
			}
			const data = await response.json();
			setSearchData(data);
			setShowResults(true);
		} catch (err: any) {
			setError(err.message);
		} finally {
			setIsLoading(false);
		}
	}, []);

	const handleSubmit = async (
		values: {searchTerm: string},
		{setSubmitting}: FormikHelpers<{searchTerm: string}>,
	) => {
		const trimmedSearchTerm = values.searchTerm.trim();
		if (
			trimmedSearchTerm &&
			!validateSearchInput(
				trimmedSearchTerm,
				activationType ?? 'code',
				getValidationErrorMessage(),
			)
		) {
			await fetchData(trimmedSearchTerm);
			setSearchTerm(trimmedSearchTerm);
		}
		setSubmitting(false);
	};

	const handleBack = () => {
		setShowResults(false);
	};

	const refreshData = async () => {
		if (searchTerm) {
			await fetchData(searchTerm);
		}
	};

	return (
		<>
			{!showResults ? (
				<Box
					marginTop={isMobile ? '24px' : '112px'}
					display="flex"
					flexDirection="column"
					alignItems="center"
					width={isMobile ? '100%' : '624px'}
					paddingX={isMobile ? 'spacing.l' : '0'}
				>
					<Heading data-testid="search-heading" mb="spacing.2xl">
						{t('generic_title_search_d4p-web')}
					</Heading>
					<Formik
						initialValues={{searchTerm: ''}}
						onSubmit={handleSubmit}
						validateOnChange={false}
						validateOnBlur={false}
						validate={(values) => {
							const errors: {searchTerm?: string} = {};
							const inputError = validateSearchInput(
								values.searchTerm.trim(),
								activationType ?? 'code',
								getValidationErrorMessage(),
							);
							if (inputError) {
								errors.searchTerm = inputError;
							}
							return errors;
						}}
					>
						{({
							values,
							errors,
							touched,
							setErrors,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
						}) => (
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width="100%"
							>
								<FormControl
									isInvalid={
										!!error || !!(errors.searchTerm && touched.searchTerm)
									}
									mb="spacing.l"
								>
									<InputGroup width="100%">
										<InputLeftElement pointerEvents="none">
											<SearchOutlinedIcon />
										</InputLeftElement>
										<Input
											placeholder={getInputPlaceholder()}
											name="searchTerm"
											value={values.searchTerm}
											onChange={(e) => {
												handleChange(e);
												setErrors({});
											}}
											onBlur={handleBlur}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													e.preventDefault();
													handleSubmit();
												}
											}}
											data-testid="search-field"
										/>
									</InputGroup>
									{(error || (errors.searchTerm && touched.searchTerm)) && (
										<FormErrorMessage data-testid="error-container">
											{errors.searchTerm ||
												(error
													? t('errormessage_text_customernotfound_d4p-web')
													: null)}
										</FormErrorMessage>
									)}
								</FormControl>

								<Button
									leftIcon={<SearchOutlinedIcon />}
									onClick={() => handleSubmit()}
									isLoading={isSubmitting || isLoading}
									data-testid="search-button"
								>
									{t('generic_title_search_d4p-web')}
								</Button>
							</Box>
						)}
					</Formik>
				</Box>
			) : (
				<Box paddingX={isMobile ? 'spacing.l' : '0'}>
					<Button
						data-testid="back-button"
						onClick={handleBack}
						variant="ghost"
						leftIcon={<ChevronLeftIcon />}
						marginBottom="spacing.xl"
					>
						{tLegacy('generic_action_goback_web')}
					</Button>
					{searchData && (
						<CustomerDetails
							data={searchData.data}
							included={searchData.included}
							onCustomerUpdated={refreshData}
						/>
					)}
				</Box>
			)}
		</>
	);
};

export default SearchComponent;
